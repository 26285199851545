import * as React from 'react';
import styled from 'styled-components';

export default function IndexPage() {
  return (
    <Main>
      <Links>
        <LinkItem>
          <a href="https://open.spotify.com/artist/0B4q2xObl8gjNPdq25pARK?si=VkX-IQQBQc2TaChI2V-DoA">
            Music
          </a>
        </LinkItem>
        <LinkItem>
          <a href="mailto:krantz.timo@gmail.com">Contact</a>
        </LinkItem>
        <LinkItem>
          <a href="https://www.instagram.com/timokrantz/">etc</a>
        </LinkItem>
      </Links>
    </Main>
  );
}

const Main = styled.main`
  align-items: flex-end;
  color: #2f3221;
  display: flex;
  font-family: -apple-system, Roboto, sans-serif, serif;
  font-weight: normal;
  height: 100vh;
  font-size: 2em;
`;

const Links = styled.ul`
  list-style: none;
  margin-bottom: 20px;
  padding: 15px;
`;

const LinkItem = styled.li`
  a {
    color: #2f3221;
    text-decoration: none;
    text-transform: lowercase;
    &:visited {
      color: #2f3221;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;
